<!-- MODAL HEADER-->
<div class="row modal-bar">

  <div class="col-sm-6">
    <h5 class="modal-title">{{'SALES.ORDERS.DETAILS.TITLE' | translate : {orderId: orderDetails.Id} }}</h5>
  </div>

  <div class="col-sm-6">
    <div class="modal-actions">
      <div class="modal-close">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span (click)="dialogRef.close(false);">×</span>
        </button>
      </div>
    </div>
  </div>
</div>


<div class="object-actions mt-3" *ngIf="!orderDetails.Deleted && !isInArchiveMode">

  <button (click)="prolongSelectedOrder()" [disabled]="orderDetails.HasProlongationLines"
    class="btn btn-primary border btn-small" type="button">
    <i class="fas fa-redo"></i>
    {{'SALES.ORDERS.DETAILS.BTN.PROLONG' | translate}}
  </button>

  <button *ngIf="showButton[getButtons().SendPaymentRequest]" (click)="initiatePayPerEmail()"
    [disabled]="orderDetails.PayPerMailAllowed == false" class="btn btn-primary border btn-small" type="button">
    <i class="far fa-credit-card"></i>
    {{'SALES.ORDERS.DETAILS.BTN.SEND-PAYMENT-REQUEST' | translate}}
  </button>

  <span class="separator"></span>
  <button (click)="printPackagingSlip()" *ngIf="showButton[getButtons().PrintOrderConfirmation]"
    class="btn btn-primary border btn-small" type="button"><i class="fas fa-receipt"></i>{{'SALES.ORDERS.DETAILS.BTN.ORDER-CONFIRMATION' | translate}}</button>
  <!-- <button *ngIf="showButton[getButtons().DownloadETickets]" (click)="displayDownloadDialog()" class="btn btn-primary border btn-small" type="button"><i class="fas fa-download"></i>E-tickets downloaden</button>
  <button *ngIf="showButton[getButtons().PrintTickets]" (click)="displayPrintDialog()" class="btn btn-primary border btn-small" type="button"><i class="fas fa-print"></i>Printen</button> -->
  
  <button (click)="printInvoice()" *ngIf="showButton[getButtons().PrintInvoice]"
    class="btn btn-primary border btn-small" type="button"><i class="fas fa-file-invoice"></i>{{'SALES.ORDERS.DETAILS.BTN.INVOICE' | translate}}</button>

  <button class="btn dropdown-toggle btn-primary border btn-small" type="button" id="dropdownMenuButton"
    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <i class="fas fa-ticket-alt"></i>
    {{'ORDER.DETAILS.BUTTON.TICKETS' | translate}}
  </button>

  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" x-placement="bottom-start"
    style="position: absolute; transform: translate3d(0px, 41px, 0px); top: 0px; left: 0px; will-change: transform;">
    <a class="dropdown-item" (click)="displayDownloadDialog();"
      [class.disabled]="orderDetails.AllowEticketPrinting === false" href="#">
      <i class="fas fa-download mr-2"></i>
      {{'ORDER.DETAILS.BUTTON.TICKETS.DOWNLOAD-ETICKET' | translate}}
    </a>
    <a class="dropdown-item" (click)="displayEmailDialog();"
      [class.disabled]="orderDetails.AllowEticketMailing === false" href="#">
      <i class="fas fa-envelope mr-2"></i>
      {{'ORDER.DETAILS.BUTTON.TICKETS.SEND-ETICKET' | translate}}
    </a>
    <a class="dropdown-item" (click)="displayPrintDialog()" [class.disabled]="orderDetails.AllowSoftcard === false"
      href="#">
      <i class="fas fa-print mr-2"></i>
      {{'ORDER.DETAILS.BUTTON.TICKETS.PRINT' | translate}}
    </a>
    <a class="dropdown-item" (click)="displayHardcardDialog()"
      [class.disabled]="orderDetails.AllowHardcardPrinting === false" href="#">
      <i class="fas fa-address-card mr-2"></i>
      {{'ORDER.DETAILS.BUTTON.TICKETS.PRINT-HARDCARDS' | translate}}
    </a>
  </div>

  <span *ngIf="orderDetails !== null && !orderDetails.Locked && orderDetails.PayByInvoice && !orderDetails.IsInvoiced">
    <span class="separator"></span>
    <button (click)="createInvoice()" class="btn btn-warning border btn-small" type="button"><i
        class="fas fa-file-invoice"></i>{{'SALES.ORDERS.DETAILS.BTN.DIRECT-INVOICE' | translate}}</button>
  </span>

  <span *ngIf="(!isReadOnly || showButton[getButtons().Edit])">
    <span class="separator"></span>
    <button (click)="editSelectedOrder()" class="btn btn-warning border btn-small" type="button"><i
        class="far fa-edit"></i>{{'GENERIC.BUTTON.EDIT' | translate}}</button>
  </span>
</div>
<div *ngIf="secondaryTicketingActive" class="alert alert-warning text-center col-sm-12">{{'SALES.ORDERS.DETAILS.WARNING.HAS-SECONDARY-ORDERS' | translate}}</div>


<t4-order-header [isIbaActive]="isIbaActive" [details]="orderDetails"></t4-order-header>

<t4-tabs (selected)="onTabChange($event)">

  <t4-tab tabTitle="{{'SALES.ORDERS.DETAILS.TABS.TITLE.ORDERLINES' | translate}}" id="orderlines">
    <t4-order-lines [orderLines]="orderDetails.OrderLines" [secondaryTicketingActive]="secondaryTicketingActive" [isIbaActive]="isIbaActive"></t4-order-lines>
  </t4-tab>

  <t4-tab tabTitle="{{'SALES.ORDERS.DETAILS.TABS.TITLE.DISCOUNTS' | translate}}" id="discounts">
    <t4-discount-lines [discountLines]="orderDetails.DiscountTotals"></t4-discount-lines>
  </t4-tab>

  <t4-tab tabTitle="{{'SALES.ORDERS.DETAILS.TABS.TITLE.MEMBERSHIPS' | translate}}" id="subscriptions">
    <t4-subscription-lines [orderDetails]="orderDetails"></t4-subscription-lines>
  </t4-tab>

  <t4-tab tabTitle="{{'SALES.ORDERS.DETAILS.TABS.TITLE.PAYMENT-HISTORY' | translate}}" id="payment-history">
    <app-payment-overview #paymentOverview></app-payment-overview>
  </t4-tab>

</t4-tabs>
