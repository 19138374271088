/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ActivityTypeEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31 | 32 | 33 | 34 | 35 | 36 | 37 | 38 | 39 | 40 | 41 | 42;

export const ActivityTypeEnum = {
    NUMBER_1: 1 as ActivityTypeEnum,
    NUMBER_2: 2 as ActivityTypeEnum,
    NUMBER_3: 3 as ActivityTypeEnum,
    NUMBER_4: 4 as ActivityTypeEnum,
    NUMBER_5: 5 as ActivityTypeEnum,
    NUMBER_6: 6 as ActivityTypeEnum,
    NUMBER_7: 7 as ActivityTypeEnum,
    NUMBER_8: 8 as ActivityTypeEnum,
    NUMBER_9: 9 as ActivityTypeEnum,
    NUMBER_10: 10 as ActivityTypeEnum,
    NUMBER_11: 11 as ActivityTypeEnum,
    NUMBER_12: 12 as ActivityTypeEnum,
    NUMBER_13: 13 as ActivityTypeEnum,
    NUMBER_14: 14 as ActivityTypeEnum,
    NUMBER_15: 15 as ActivityTypeEnum,
    NUMBER_16: 16 as ActivityTypeEnum,
    NUMBER_17: 17 as ActivityTypeEnum,
    NUMBER_18: 18 as ActivityTypeEnum,
    NUMBER_19: 19 as ActivityTypeEnum,
    NUMBER_20: 20 as ActivityTypeEnum,
    NUMBER_21: 21 as ActivityTypeEnum,
    NUMBER_22: 22 as ActivityTypeEnum,
    NUMBER_23: 23 as ActivityTypeEnum,
    NUMBER_24: 24 as ActivityTypeEnum,
    NUMBER_25: 25 as ActivityTypeEnum,
    NUMBER_26: 26 as ActivityTypeEnum,
    NUMBER_27: 27 as ActivityTypeEnum,
    NUMBER_28: 28 as ActivityTypeEnum,
    NUMBER_29: 29 as ActivityTypeEnum,
    NUMBER_30: 30 as ActivityTypeEnum,
    NUMBER_31: 31 as ActivityTypeEnum,
    NUMBER_32: 32 as ActivityTypeEnum,
    NUMBER_33: 33 as ActivityTypeEnum,
    NUMBER_34: 34 as ActivityTypeEnum,
    NUMBER_35: 35 as ActivityTypeEnum,
    NUMBER_36: 36 as ActivityTypeEnum,
    NUMBER_37: 37 as ActivityTypeEnum,
    NUMBER_38: 38 as ActivityTypeEnum,
    NUMBER_39: 39 as ActivityTypeEnum,
    NUMBER_40: 40 as ActivityTypeEnum,
    NUMBER_41: 41 as ActivityTypeEnum,
    NUMBER_42: 42 as ActivityTypeEnum
};

