<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-9">
      <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-3">
      <div class="modal-actions">
          <div class="modal-close">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span (click)="dialogRef.close(false);">×</span>
              </button>
          </div>
      </div>
  </div>
</div>

<form [formGroup]="form" class="form-content">
  <div class="section-wrapper">
      <div class="form-layout">
          <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="form-control-label">{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.NAME' | translate}} <span class="tx-danger">*</span></label>
                  <input class="form-control" name="name" formControlName="Name" placeholder="{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.PLACEHOLDER.NAME' | translate}}" maxlength="64" [readonly]="this.systemManaged">
                  <t4-display-validation controlName="Name" [form]="form" [messages]="validationMessages"></t4-display-validation>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label class="form-control-label">{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.CODE' | translate}} <span class="tx-danger">*</span></label>
                  <input class="form-control" name="Code" formControlName="Code" placeholder="{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.PLACEHOLDER.CODE' | translate}}" maxlength="64" [readonly]="this.systemManaged">
                  <t4-display-validation controlName="Code" [form]="form" [messages]="validationMessages"></t4-display-validation>
                </div>
              </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label">{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.CUSTOMER-GROUP' | translate}} <span class="tx-danger" *ngIf="isRequiredField('SalesGroupId')">*</span></label>

                <ng-select [items]="salesGroups" bindLabel="Name" bindValue="Id" formControlName="SalesGroupId" placeholder="{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.PLACEHOLDER.CUSTOMER-GROUP' | translate}}"
                           [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" [readonly]="this.systemManaged"></ng-select>

                <t4-display-validation controlName="SalesGroupId" [form]="form" [messages]="validationMessages"></t4-display-validation>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label">{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.DISTRIBUTION-CHANNEL' | translate}} <span class="tx-danger" *ngIf="isRequiredField('DistributionChannelId')">*</span></label>

                <ng-select [items]="distributionChannels" bindLabel="Name" bindValue="Id" formControlName="DistributionChannelId" placeholder="{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.PLACEHOLDER.DISTRIBUTION-CHANNEL' | translate}}"
                           [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" [readonly]="this.systemManaged"></ng-select>

                <t4-display-validation controlName="DistributionChannelId" [form]="form" [messages]="validationMessages"></t4-display-validation>
              </div>
            </div>
          </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.TICKETSHOPHEADERLINKTEXT' | translate}} <span class="tx-danger">*</span></label>
              <input class="form-control" name="ticketshopHeaderLinkText" formControlName="TicketshopHeaderLinkText" placeholder="{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.PLACEHOLDER.TICKETSHOPHEADERLINKTEXT' | translate}}" maxlength="64">
              <t4-display-validation controlName="TicketshopHeaderLinkText" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-control-label">{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.PART-OF' | translate}} <span class="tx-danger" *ngIf="isRequiredField('DistributionChannelId')">*</span></label>

              <ng-select [items]="salesChannels" bindLabel="Name" bindValue="Id" formControlName="ParentId" placeholder="{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.PLACEHOLDER.PART-OF' | translate}}"
                         [clearable]="true" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body" [readonly]="this.systemManaged"></ng-select>

              <t4-display-validation controlName="ParentId" [form]="form" [messages]="validationMessages"></t4-display-validation>
            </div>
          </div>
        </div>

          <div class="row">

            <div class="col-lg-11">
              <div class="form-group">
                <label class="form-control-label">{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.LOGO' | translate}}</label>
                <ng-select [items]="images" bindLabel="Description" bindValue="Id" formControlName="ImageId" placeholder="{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.PLACEHOLDER.LOGO' | translate}}"
                           [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item">
                    <div style="display: flex; align-items: center">
                      <div style="width:32px; height:32px; margin-right: 16px;">
                        <img [src]="fileHelper.getResizedImageUrl(item.Url, 32, 32)" style="margin: 0 auto; display: block;"/>
                      </div>
                      <span>{{ item.Description }}</span>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>

            <div class="col-lg-1" style="padding-top: 8px;">
              
              <div class="form-group">
                <label class="form-control-label"></label>
                <button (click)="openFileInputDialog()" class="btn btn-block btn-primary bd-0">+</button>
              </div>

            </div>


          </div>

          <div class="row">

            <div class="col-lg-11">
              <div class="form-group">
                <label class="form-control-label">{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.TENANT-SHOP' | translate}}</label>
                <ng-select [items]="tenantShops" bindLabel="Subdomain" bindValue="Id" formControlName="TenantShopId" placeholder="{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.PLACEHOLDER.TENANT-SHOP' | translate}}"
                           [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item">
                    <div style="display: flex; align-items: center">
                      <!-- <div style="width:32px; height:32px; margin-right: 16px;">
                        <img [src]="fileHelper.getResizedImageUrl(item.Url, 32, 32)" style="margin: 0 auto; display: block;"/>
                      </div> -->
                      <span>{{ item.Subdomain }}</span>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>

            <!-- <div class="col-lg-1" style="padding-top: 8px;">
              
              <div class="form-group">
                <label class="form-control-label"></label>
                <button (click)="openFileInputDialog()" class="btn btn-block btn-primary bd-0">+</button>
              </div>

            </div> -->


          </div>

          <div class="row">

            <div class="col-lg-6">

              <div class="form-group">
                <div>

                  <div fxFlex class="mt-1">
                    <input type="checkbox" name="HasBackgroundColor" formControlName="HasBackgroundColor" class="mr-2">
                    <label class="form-check-label" for="HasBackgroundColor">{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.COLOR' | translate}}</label>
                  </div>
                  <ng-container *ngIf="form.controls['HasBackgroundColor'].value === true">
                    <div fxFlex="30px"><div class="color-picker-display" [(colorPicker)]="color" [style.background]="color">&nbsp;</div></div>
                    <div class="pt-1" fxFlex>{{ color }}</div>
                  </ng-container>

                </div>
              </div>

            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <div>
                  <div fxFlex class="mt-1">
                    <input type="checkbox" name="HasForeColor" formControlName="HasForeColor" class="mr-2">
                    <label class="form-control-label">{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.FORECOLOR' | translate}} </label>
                  </div>

                  <ng-container *ngIf="form.controls['HasForeColor'].value === true">
                    <div fxFlex="30px"><div class="color-picker-display" [(colorPicker)]="foreColor" [style.background]="foreColor">&nbsp;</div></div>
                    <div class="pt-1" fxFlex>{{ foreColor }}</div>
                  </ng-container>

                </div>
              </div>
            </div>

          </div>
        <div class="row" *ngIf="hasSecondaryTicketing === true">
          <div class="col-lg-6">
            <div class="form-group">
              <input type="checkbox" id="ShowMarketplaceForEvent" formControlName="ShowMarketplaceForEvent">
              <label class="form-check-label" for="ShowMarketplaceForEvent">{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.SHOW-MARKETPLACE-FOR-EVENT' | translate}}</label>
              <span class="mb-3 d-block">
                {{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.SHOW-MARKETPLACE-FOR-EVENT.DESCRIPTION' | translate}}
              </span>

            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">

              <input type="checkbox" id="RedirectToMarketplace" formControlName="RedirectToMarketplace">
              <label class="form-check-label" for="RedirectToMarketplace">{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.REDIRECT_TO_MARKETPLACE' | translate}}</label>

              <span class="mb-3 d-block">
                {{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.REDIRECT_TO_MARKETPLACE.DESCRIPTION' | translate}}
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <input type="checkbox" id="AllowAnonymous" formControlName="AllowAnonymous">
              <label class="form-check-label" for="AllowAnonymous">{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.ALLOW-ANONYMOUSE' | translate}}</label>
              <span class="mb-3 d-block">
                {{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.ALLOW-ANONYMOUSE.DESCRIPTION' | translate}}
              </span>

            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">

              <input type="checkbox" id="UserSelectable" formControlName="UserSelectable">
              <label class="form-check-label" for="UserSelectable">{{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.USER-SELECTABLE' | translate}}</label>

              <span class="mb-3 d-block">
                {{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.USER-SELECTABLE.DESCRIPTION' | translate}}
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="ckbox">
                <input type="checkbox" name="Active" formControlName="Active"><span>  {{'MANAGEMENT.SALESCHANNEL.EDITDIALOG.FORM.LABEL.ACTIVE'| translate }}</span>
              </label>

            </div>
          </div>
        </div>

          <div class="form-layout-footer row">
            <div class="col-6">
              <button (click)="submit()" [disabled]="form.invalid || saving" class="btn btn-block btn-primary bd-0"><i
                class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
            </div>
            <div class="col-6">
              <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
                class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
            </div>

          </div><!-- form-layout-footer -->
      </div>
  </div>




</form>
