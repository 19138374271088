import { Subscriber, forkJoin } from 'rxjs';
import { FileService, HttpLoaderService } from '@tymes4-shared';
import { KeyValuePair } from './../../models/key-value-pair';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, AbstractControl, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SalesChannelValidator } from '../../validators/sales-channel-validator';
import { FileInputDialogComponent } from '../file-input-dialog/file-input-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DistributionChannelPagedResult, DistributionChannelService, ImageListItem, ImageService, ImageTypeEnum, SalesChannel, SalesChannelService, SalesGroupPagedResult, SalesGroupService, TenantShop, TenantShopService } from '../../api';
import { ApplicationModuleHelperService, ApplicationModules } from '../../services/application-module.service';

@Component({
    selector: 'app-sales-channel-form',
    templateUrl: './edit-sales-channel-form.component.html'
})
export class EditSalesChannelComponent implements OnInit {
    public form: FormGroup;

    public saving = false;

    private isNew: boolean = true;
    private editObject: any = null;
    public color: any;
    public foreColor: any;
    public images: any = [];
    public systemManaged = false;
    public hasSecondaryTicketing : boolean = false;
    public tenantShops: Array<TenantShop>;

    salesGroups: any;
    distributionChannels: any;
    salesChannels: any;

    validationMessages = [];

    private requiredFields: KeyValuePair<boolean> = null;

    isRequiredField(field: string, targetForm: FormGroup = null): boolean {

        if (this.requiredFields === null) {
          this.requiredFields = {};
        }

        if (this.requiredFields[field] === null || this.requiredFields[field] === undefined) {

          const form = targetForm ? targetForm : this.form;
          const formField = form.get(field);

          if (!formField.validator) {
            return false;
          }

          const validator = formField.validator({} as AbstractControl);
          this.requiredFields[field] = validator && validator.required;

          formField.validator(formField);
        }
        return this.requiredFields[field];
      }

    constructor(
      @Inject(MAT_DIALOG_DATA) public passedData: any,
      public dialogRef:  MatDialogRef<EditSalesChannelComponent>,
      private salesChannelValidator: SalesChannelValidator,
      private loader:HttpLoaderService,
      private salesChannelService: SalesChannelService,
      private distributionChannelService: DistributionChannelService,
      private salesGroupService: SalesGroupService, 
      private fileHelper: FileService,
      private imageService: ImageService,
      private dialog: MatDialog,
      private translate: TranslateService,
      private applicationModuleHelperService: ApplicationModuleHelperService,
      private tenantShopService: TenantShopService
    ) { }

    ngOnInit() {

      this.editObject = this.passedData.payload;
      this.salesChannelValidator.editingObject = this.editObject;

      if (this.editObject == null) {
        this.isNew = true;
        this.editObject = {};
        this.editObject.HasBackgroundColor = false;
        this.editObject.HasForeColor = false;
      }
      else
      {
        this.isNew = false;
        this.color = this.editObject.HexColor;
        this.foreColor = this.editObject.HexForeColor;
        this.editObject.HasBackgroundColor = this.editObject.HexColor !== null;
        this.editObject.HasForeColor = this.editObject.HexForeColor !== null;
        this.systemManaged = this.editObject.SystemManaged;
      }

      this.form = new FormGroup({
        Id: new FormControl(0),
        Name: new FormControl('', [Validators.required]),
        Code: new FormControl('', {
          validators: Validators.required,
          asyncValidators: [
            this.salesChannelValidator.codeTaken.bind(this)
        ]}),
        HexColor: new FormControl(''),
        HexForeColor: new FormControl(''),
        DistributionChannelId: new FormControl('', [Validators.required]),
        SalesGroupId: new FormControl('', [Validators.required]),
        ParentId: new FormControl(''),
        ShowMarketplaceForEvent: new FormControl(false),
        RedirectToMarketplace: new FormControl(false), 
        Active: new FormControl(false), 
        ImageId: new FormControl(null),
        HasForeColor: new FormControl(false),
        HasBackgroundColor: new FormControl(false),
        //TODO: Velden komen uit develop. Is nodig voor database
        TicketshopHeaderLinkText: new FormControl(''),
        AllowAnonymous: new FormControl(false),
        UserSelectable: new FormControl(true),   
        //TicketshopHeaderLinkText: new FormControl('', [Validators.required]),
        //AllowAnonymous: new FormControl({value: false, disabled: this.regularShop}),
        //UserSelectable: new FormControl({value: true, disabled: this.regularShop})
        TenantShopId: new FormControl(null)
      });
      
      let call0 = this.salesGroupService.searchSalesGroups('', 1, 9999999);
      let call1 = this.distributionChannelService.searchDistributionChannels('', 1, 9999999);
      let call2 = this.salesChannelService.listAllSystemManagedSalesChannels();
      let call3 = this.refreshImages();
      let call4 = this.tenantShopService.listAllTenantShops();

      forkJoin([call0, call1, call2, call3, call4]).subscribe(([salesGroups, distributionChannels, salesChannels, images, tenantShops]: [SalesGroupPagedResult, DistributionChannelPagedResult, Array<SalesChannel>, Array<ImageListItem>, Array<TenantShop>]) => {
        this.salesGroups = salesGroups.Records;
        this.distributionChannels = distributionChannels.Records;
        this.salesChannels = salesChannels;
        this.images = images;
        this.tenantShops = tenantShops;
      });

      this.applicationModuleHelperService.getActiveModules().subscribe((module) => {
        this.hasSecondaryTicketing = (module && module.includes(ApplicationModules.SecondaryTicketing));
      })

      this.form.patchValue(this.editObject);
    }

    refreshImages() {
      return this.imageService.getAllImages(ImageTypeEnum.Default);
    }

    openFileInputDialog() {
      const fileInputDialogRef: MatDialogRef<any> = this.dialog.open(FileInputDialogComponent, {
        disableClose: false,
        data: {title: this.translate.instant('DIALOGS.EDIT-SALES-CHANNEL.FORM.UPLOAD-FILE.TITLE'), description: this.translate.instant('DIALOGS.EDIT-SALES-CHANNEL.FORM.UPLOAD-FILE.MESSAGE')}
      });
      fileInputDialogRef.afterClosed()
        .subscribe(data => {
            if (data) {
              this.refreshImages().subscribe((result) => {
                this.images = result;
                if (data) {
                  const imageIdControl = this.form.get('ImageId') as FormControl;
                  imageIdControl.patchValue(data);
                }
              });
            }
          }
        );
    }
    
    submit() {
      let formData = this.form.value;

      formData.HexColor = formData.HasBackgroundColor === true ? this.color : null;
      formData.HexForeColor = formData.HasForeColor === true ? this.foreColor : null;
      
      if (this.systemManaged === true) {
        //copy the disabled properties over
        formData.Name = this.editObject.Name;
        formData.Code = this.editObject.Code;
        formData.DistributionChannelId = this.editObject.DistributionChannelId;
        formData.SalesGroupId = this.editObject.SalesGroupId;
        formData.ParentId = this.editObject.ParentId;
        formData.SystemManaged = true;
      }

      this.saving = true;
      this.loader.open();

      if (this.isNew)
      {
        this.salesChannelService.createSalesChannel(formData).subscribe((id:number) => {
          this.dialogRef.close(id);
          this.loader.close();
        })
      }
      else {
        this.salesChannelService.updateSalesChannel(formData).subscribe(() => {
          this.dialogRef.close(this.editObject.Id);
          this.saving = false;
          this.loader.close();
        })
      }

    }

}
